import React from 'react';
import '../../App.scss';
import '../HeroSection/HeroSection.scss';
export default function About() {

  document.title = `Code de la Famille - A propos`;
  return (
    <div style={{
      background: `url('/images/djammadev.jpg')`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>

      <h1 className="about"> DjammaDev </h1>
    </div>
  )
}

