import React from 'react';
import CardItems from './CardItems';
import './Cards.scss';

function Cards() {
  return (
    <div className="cards">
      <h1> Un ensemble de droits et règles</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItems
              src="/images/mali2.jpg"
              text=" qui font de nous un Peuple Unis dans la diversité"
              label='Diversifié'
              path='/livres'
            />
            <CardItems
              src="/images/mali3.jpg"
              text="qui construisent une société à l'image de tout un chacun"
              label='Batisseur'
              path='/livres'
            />
          </ul>
          <ul className="cards__items">
            <CardItems
              src="/images/mali4.png"
              text="Qui font de nous un peuple unis dans la solidarité"
              label='Liberté'
              path='/livres'
            />
            <CardItems
              src="/images/img-8.jpg"
              text="avec une histoire de haute renommée au délà des frontières du sahara des terres lointaines "
              label='Exploration'
              path='/livres'
            />
            <CardItems
              src="/images/mali5.jpg"
              text="Un Peuple - Un But - Une Foi"
              label='Souveraineté'
              path='/docs'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Cards
