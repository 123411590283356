import React, { useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import './Navbar.scss'
import firebase from '../utils/firebaseConfig';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  // const articleCtx = useContext(ContentContext);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    }
    else {
      setButton(true);
    }
  }


  window.addEventListener('resize', showButton);
  const [isLogged, setisLogged] = useState(false);

  const loggingHandler = useCallback(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      
      if (user) {
        // User is signed in.
        setisLogged(true);
      } else {
        // No user is signed in.
        setisLogged(false);
      }

      closeMobileMenu();
    });
  }, [])
  const logout = () => {
    window.location.reload()
    firebase.auth().signOut();
    closeMobileMenu();
    loggingHandler();
  }

  useEffect(() => {
    showButton();
    loggingHandler();
  }, [loggingHandler])
  
  return (

    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            Mali <i className="fab fa-typo3" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to="/" className='nav-links' onClick={closeMobileMenu} >
                Accueil
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="/livres" className='nav-links' onClick={closeMobileMenu} >
                Livres
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="/livres/all/articles" className='nav-links' onClick={closeMobileMenu} >
                Articles
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="/About" className='nav-links' onClick={closeMobileMenu} >
                A propos
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="/sign-up" className='nav-links-mobile' onClick={loggingHandler} >
                {isLogged ? "Deconnexion" : "Connexion"}
              </Link>
            </li>
            <li className='nav-item'>
              {isLogged ? 
              (button && <Button onClick={logout} buttonStyle='btn--outline' Link_i="/sign-up">
                Deconnexion
              </Button>) : 
              (button && <Button onClick={loggingHandler} buttonStyle='btn--outline' Link_i="/sign-up">
                Connexion
              </Button>)} 
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar
