import React, { useEffect, useRef, useState } from 'react';
import './CreateArticle.scss';
import { Button } from '../Button/Button';
import _ from 'underscore'
import firebase from '../utils/firebaseConfig';
// import * as data from '../../Data.json';
function CreacteArticle() {

  // const [article, setArticle] = useState([]);
  // const [sousSection, setsousSection] = useState([]);
  // const [section, setSection] = useState([]);
  // const [chapitre, setChapitre] = useState([]);
  const [titre, SetTitre] = useState([]);
  const [livre, setLivre] = useState([]);
  const contentArticleInputRef = useRef();
  const titleArticleInputRef = useRef();
  const numberArticleInputRef = useRef();
  const sousSectionInputRef = useRef();
  const sectionInputRef = useRef();
  const chapitreInputRef = useRef();
  const titreInputRef = useRef();
  const livreInputRef = useRef();
  const ArticlesDB = firebase.database().ref("Articles");
  const LivresDB = firebase.database().ref("Livres");
  const TitresDB = firebase.database().ref("Titres");
  const ChapitresDB = firebase.database().ref("Chapitres");
  const SectionsDB = firebase.database().ref("Sections");
  const SousSectionsDB = firebase.database().ref("SousSections");
  let enteredContentArticle = ""
  let enteredTitleArticle = "";;
  let enteredNumberArticle = "";
  let enteredSousSection = "";
  let enteredSection = "";
  let enteredChapitre = "";
  let enteredTitre = "";
  let enteredLivre = "";
  let key_livre_pushed = "";
  let key_titre_pushed = "";
  let key_chapitre_pushed = "";
  let key_section_pushed = "";
  let key_sous_section_pushed = "";
  let key_article_pushed = "";

  const submitHandler = (event) => {
    event.preventDefault();

    enteredContentArticle = contentArticleInputRef.current.value;
    enteredTitleArticle = titleArticleInputRef.current.value;
    enteredNumberArticle = numberArticleInputRef.current.value;
    enteredSousSection = sousSectionInputRef.current.value;
    enteredSection = sectionInputRef.current.value;
    enteredChapitre = chapitreInputRef.current.value;
    enteredTitre = titreInputRef.current.value;
    enteredLivre = livreInputRef.current.value;



    if (enteredLivre !== "") {


      const NewLivre = {
        title: enteredLivre,
      }
      livreInputRef.current.value = "";

      if (!(livre.some(item => item.title === enteredLivre))) {
        key_livre_pushed = LivresDB.push(NewLivre).key;
        console.log(key_livre_pushed);
      }

      if (enteredTitre !== "") {
        const NewTitre = {
          title: enteredTitre,
          livre_id: key_livre_pushed
        }
        key_titre_pushed = TitresDB.push(NewTitre).key;
        // console.log(key_titre_pushed);
        titreInputRef.current.value = "";

        if (enteredChapitre !== "") {
          const NewChapitre = {
            title: enteredChapitre,
            titre_id: key_titre_pushed
          }
          key_chapitre_pushed = ChapitresDB.push(NewChapitre).key;
          // console.log(key_chapitre_pushed);
          chapitreInputRef.current.value = "";

          if (enteredSection !== "") {
            const NewSection = {
              title: enteredSection,
              chapitre_id: key_chapitre_pushed,
            }
            key_section_pushed = SectionsDB.push(NewSection).key;
            // console.log(key_chapitre_pushed);
            sectionInputRef.current.value = "";

            if (enteredSousSection !== "") {
              const NewSousSection = {
                title: enteredSousSection,
                section_id: key_section_pushed
              }
              key_sous_section_pushed = SousSectionsDB.push(NewSousSection).key;
              // console.log(key_chapitre_pushed);
              sousSectionInputRef.current.value = "";
            }

          }
        }
      }
      if (enteredTitleArticle !== "" && enteredNumberArticle !== "" && enteredContentArticle !== "") {
        key_livre_pushed = livre.filter((item) => (enteredLivre === item.title))[0].id;
        console.log("new key livre", key_livre_pushed);

        const NewArticle = {
          Number: enteredNumberArticle,
          TitreArticle: enteredTitleArticle,
          Content: enteredContentArticle,
          livre_id: key_livre_pushed,
          titre_id: key_titre_pushed,
          chapitre_id: key_chapitre_pushed,
          section_id: key_section_pushed,
          sousSection_id: key_sous_section_pushed,
        };
        numberArticleInputRef.current.value = "";
        titleArticleInputRef.current.value = "";
        contentArticleInputRef.current.value = "";
        key_article_pushed = ArticlesDB.push(NewArticle).key;
        console.log(key_article_pushed);
      }

    }



  }

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const myPreviousLivreState = usePrevious(livre);
  const myPreviousTitreState = usePrevious(titre);

  useEffect(() => {
    LivresDB.on('value', (livre_snapshot) => {
      const list = [];
      let previousLivre = livre_snapshot.val();
      for (let id in previousLivre) {
        list.push({ id, ...previousLivre[id] });
      }
      if (!_.isEqual(myPreviousLivreState, list)) {
        setLivre(list);
      }
    })
    TitresDB.on('value', (titre_snapshot) => {
      const list = [];
      let previousTitre = titre_snapshot.val();
      for (let id in previousTitre) {
        // if(previousTitre.livre_id === livre.filter((item) => ( enteredLivre === item.title))[0].id){
        list.push({ id, ...previousTitre[id] });
        // }
      }
      if (!_.isEqual(myPreviousTitreState, list)) {
        SetTitre(list);
      }
    })

  }, [TitresDB, LivresDB, setLivre, myPreviousTitreState, myPreviousLivreState])


  const onChange = (e) => {
    e.preventDefault();
    console.log(" livre selectionner est : ", livre.filter((item) => (enteredLivre === item.title)))


  }

  // const testHandler = (e) => {
  //   e.preventDefault();
  //   const datas = [...data["Article"]]

  //   for(let i=0; i<=1145; i++){
  //       const NewArticle = {
  //       Number: datas[i].number,
  //       TitreArticle: datas[i].Titre,
  //       Content: datas[i].content,
  //       livre_id: datas[i].livre_id ? datas[i].livre_id : "",
  //       titre_id : datas[i].titre_id ? datas[i].titre_id : "",
  //       chapitre_id : "",
  //       section_id : "",
  //       sousSection_id: "",
  //     };
  //     ArticlesDB.push(NewArticle)

  //   }
  //   console.log("ok")
  // }

  document.title = `Code de la Famille - Creation d'Articles`;
  return (
    <div className="create-article">


      {firebase.auth().currentUser && <Button buttonStyle='btn--outline' onClick={() => firebase.auth().signOut()} Link_i="/sign-up" >Bonjour Mr {firebase.auth().currentUser.displayName} <br /> Déconnectez-vous ici</Button>}
      <div className="form-add">
        <h1>Creation d'article</h1>
        <form onSubmit={submitHandler} >
          <div className="form-item">
            <label> Livre </label>
            <input type="text" placeholder="selectionner le Livre" list="livres" onChange={onChange} ref={livreInputRef} />
          </div>
          <div className="form-item">
            <label> Titre </label>
            <input type="text" placeholder="selectionner le Titre" list="titres" ref={titreInputRef} />
          </div>
          <div className="form-item">
            <label> Chapitre </label>
            <input type="text" placeholder="selectionner le Chapitre" ref={chapitreInputRef} />
          </div>
          <div className="form-item">
            <label> Section </label>
            <input type="text" placeholder="selectionner la Section" ref={sectionInputRef} />
          </div>
          <div className="form-item">
            <label> Sous-Section </label>
            <input type="text" placeholder="selectionner la Sous-Section" ref={sousSectionInputRef} />
          </div>
          <div className="form-item">
            <label> titre de l'article </label>
            <input type="text" placeholder="titre ..." ref={titleArticleInputRef} />
          </div>
          <div className="form-item">
            <label> numero de l'article </label>
            <input type="text" placeholder="Numero ..." ref={numberArticleInputRef} />
          </div>
          <div className="form-item">
            <label> contenu de l'article </label>
            <textarea rows="3" ref={contentArticleInputRef}></textarea>
          </div>
          <div className="submit">
            <button type="submit" className="btn btn--outline"> Enregistrer</button>
          </div>
        </form>

        <datalist id="livres">
          {livre.map((item) => {
            return <option key={item.id} > {item.title} </option>
          })}
        </datalist>


        {/* <datalist id="titres"> 
          <option>Red</option> 
          {titre.map((item) =>{
            
            return <option key={item.id} > {item.title} </option>
          })} 
        </datalist> */}

      </div>
    </div>
  )
}

export default CreacteArticle
