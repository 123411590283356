import React, { useState, useEffect } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import '../../App.scss';
import firebase from '../utils/firebaseConfig';
import './SignUp.scss';
import CreateArticle from '../createArticle/CreacteArticle';

const AUTHORIZED_USER = [
  {
    id: "AzE3pDZWe5ci8tCifR9rmvEKMFk2",
    name: "Bouky King"
  },
  {
    id: "AzE3pDZWe5ci8tCifR9rmvEKMFk2",
    name: "Konate Bakary"

  }]

export default function SignUp() {
  const [isSingned, setSignedIn] = useState(false);
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccess: () => false,
    },
  };

  useEffect(() => {

    firebase.auth().onAuthStateChanged((user) => {
      setSignedIn(!!user);
      if (user) {
        if (!(AUTHORIZED_USER.some(item => item.id === user.uid))) {
          firebase.auth().signOut();
        }
      }
    })


  }, []);

  document.title = `Code de la Famille - Connexion`;
  return (
    <div className="sign" style={{
      background: `url('/images/img-8.jpg')`
    }}>

      {(isSingned) ?
        (<CreateArticle />)
        :
        (<div className="login-page">
          <h1> Connexion</h1>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()} />
          <p>Authorisation necessaire</p>
          <p>contacter l'administrateur</p>
        </div>)}

    </div>
  )
}
