import firebase from 'firebase';

  var firebaseConfig = {
    apiKey: "AIzaSyA6sIALrhJ2B_K2EX7e6VRKyWLII2cZuhI",
    authDomain: "code-famille-mali.firebaseapp.com",
    databaseURL: "https://code-famille-mali-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "code-famille-mali",
    storageBucket: "code-famille-mali.appspot.com",
    messagingSenderId: "1030657080694",
    appId: "1:1030657080694:web:96dc8a8f2cf5452a840e57"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase ;