import React, { useState, useEffect, useRef } from 'react';
import './SearchBar.scss';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import firebase from '../utils/firebaseConfig';
import {Link} from 'react-router-dom';
import _ from 'underscore';

function SearchBar(props) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setwordEntered] = useState("");
    const  [Titre, setTitre ] = useState([]);
    const  [Livre, setLivre] = useState([]);
    const [nullData, setnullData] = useState(false);
    const [article, setArticle] = useState([]);
    const ArticlesDB = firebase.database().ref("Articles");
    const TitresDB = firebase.database().ref("Titres");
    const LivresDB = firebase.database().ref("Livres");

    const handleFilter = (event) => {
        const searchWord  = event.target.value ;
        setwordEntered(searchWord);
        setnullData(false);
        // console.log(article);
        const newFilter = article.filter((value) => {
            return Object.keys(value).some(key => value[key].toString().toLowerCase().includes(searchWord.toString().toLowerCase()))
        })
        if (searchWord === ""){
            setFilteredData([]);    
        }else{
            if(newFilter.length === 0){
                setnullData(true)
            }
            // console.log(newFilter);
            setFilteredData(newFilter);
            
        }
    }

    const handleClear = () => {
        setFilteredData([]);
        setwordEntered("");
        setnullData(false);
    }

    const handleAnswerChange = (e) =>{
		(e.key === "Escape") ? handleClear() : void(0);
    }
    const usePrevious = value => {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    };
    
    const myPreviousState = usePrevious(article);
    const myPreviousState2 = usePrevious(Livre);
    const myPreviousState3 = usePrevious(Titre);
    useEffect(() => {
        LivresDB.on('value', (livre_snapshot) => {
            let list = [];
            let previousLivre = livre_snapshot.val();
            for (let id in previousLivre) {
                list.push({ id, ...previousLivre[id] });
            }
            if (!_.isEqual(myPreviousState2, list)) {
              setLivre(list);
            }
        }); 
        TitresDB.on('value', (livre_snapshot) => {
            let list = [];
            let previousTitre = livre_snapshot.val();
            for (let id in previousTitre) {
                list.push({ id, ...previousTitre[id] });
            }
            if (!_.isEqual(myPreviousState3, list)) {
              setTitre(list);
            }
        }); 
        ArticlesDB.on('value', (article_snapshot) => {
        let previousArticle = article_snapshot.val();
        let list = [];
        for (let id in previousArticle) {
            list.push({ id, ...previousArticle[id] });
        }
        if (!_.isEqual(myPreviousState, list)) {
          setArticle(list);
        }
      })
  
    }, [ArticlesDB,LivresDB, TitresDB, myPreviousState, myPreviousState2, myPreviousState3, props])

    const findLivre = (id) => {
        // console.log(Livre.filter(item => item.id === id)[0])
        return Livre.filter(item => item.id === id)[0] ? Livre.filter(item => item.id === id)[0].title.split(" ").join("_") : "0"
    }
    const findTitre = (id) => {
        // console.log(Titre.filter(item => item.id === id)[0] ? Titre.filter(item => item.id === id)[0].title : "rien")
        return Titre.filter(item => item.id === id)[0] ? Titre.filter(item => item.id === id)[0].title.split(" ").join("_") : "0" 
    }
    return (
        <div className="search">
            <div className="searchInputs">
                <input type="text" placeholder={props.placeholder} autoFocus={true} value={wordEntered} onChange={handleFilter} onKeyDown={handleAnswerChange}/>
                <div className="searchIcon">
                    {wordEntered.length === 0 ? <SearchIcon /> : <CloseIcon id="clearBtn" onClick={handleClear} /> }
                </div>
            </div>
            {filteredData.length !== 0 && (
                <div className="dataResult">
                    {filteredData.slice(0, 15).map((value, key) => {
                        return (<Link className="dataItem" key={key} to={{
                            pathname: `/livres/${findLivre(value.livre_id)}/${findTitre(value.titre_id)}/${value.id}`,
                            state: { 
                                    Id: value.id,
                                    Number : value.Number,
                                    Titre : value.TitreArticle,
                                    Content : value.Content, 
                                    source: article,
                            }}}>

                                <p>{value.Number}: {value.TitreArticle}     </p>
                        </Link>)
                    })}
                </div>
            )}
            {
            (nullData) &&(
                <p className="notfund">"  l'article est introuvable !!  "</p>
            )}
                
        </div>
    );
}

export default SearchBar;
