import React from 'react'
import './Button.scss';
import {Link} from 'react-router-dom';


const STYLES = ['btn--primary', 'btn--outline'];

const SIZES = ['btn--medium', 'btn--large'];
 export const Button = ({
  children,
   type,
   onClick,
   buttonStyle,
   Link_i,
   key_i,
   buttonSize
    }) => {
      const checkButtonStyle = STYLES.includes(buttonStyle) 
      ? buttonStyle 
      : STYLES[0];
      const checkButtonSize = SIZES.includes(buttonSize) 
      ? buttonSize 
      : SIZES[0]

      return(
        
        // ''
        <Link to={Link_i} key={key_i} className= 'btn-mobile'>
          <button
          className={`btn  ${checkButtonStyle} ${checkButtonSize}`}
          onClick={onClick}
          type={type}
          >
            {children}
          </button>
        </Link>
      )
};
