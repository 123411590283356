import React, { useEffect, useRef, useState } from 'react';
import './Titres.scss';
import SearchBar from '../SearchBar/SearchBar'
import firebase from '../utils/firebaseConfig';
import _ from 'underscore';
import { useHistory, Link } from 'react-router-dom';

function Titres(props) {

  const [titre, setTitre] = useState([]);
  const TitresDB = firebase.database().ref("Titres");
  const LivresDB = firebase.database().ref("Livres");

  const [LivreTitre, setLivreTitre] = useState("");
  const [Livre_id, setLivre_id] = useState("");
  const history = useHistory()
  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const myPreviousState = usePrevious(titre);
  useEffect(() => {
    setLivreTitre(props.match.params.id.split("_").join(" "))
    LivresDB.on('value', (livre_snapshot) => {
      let previousLivre = livre_snapshot.val();
      for (let id in previousLivre) {
        if (previousLivre[id].title === LivreTitre) {
          setLivre_id(id);
        }
      }
    });
    TitresDB.on('value', (titre_snapshot) => {
      let previousTitre = titre_snapshot.val();
      let list = [];
      for (let id in previousTitre) {
        if (previousTitre[id].livre_id === Livre_id) {
          list.push({ id, ...previousTitre[id] });
        }
      }
      if (!_.isEqual(myPreviousState, list)) {
        setTitre(list);
      }
    })

    if (Livre_id === "-MfOMbmXuZSd_xa56Vgu") {
      setLivreTitre("LIVRE PRELIMINAIRE")
      const location = {
        pathname: `/livres/${LivreTitre.split(" ").join("_")}/0`,
        state: {
          article_id: LivreTitre,
          titre: "LIVRE PRELIMINAIRE",
          Titre_id: LivreTitre
        }
      }
      history.push(location);
    } else if (Livre_id === "-MfONw-WGuFsncuEaRz9") {
      setLivreTitre("LIVRE IX - DES DISPOSITIONS FINALES")
      const location = {
        pathname: `/livres/${LivreTitre.split(" ").join("_")}/9`,
        state: {
          Livre_id: Livre_id,
          titre: "LIVRE IX - DES DISPOSITIONS FINALES",
          Titre_id: LivreTitre
        }
      }
      history.push(location);
    }


  }, [TitresDB, LivresDB, myPreviousState, props, history, Livre_id, LivreTitre])

  document.title = `Code de la Famille - ${LivreTitre}`;
  return (
    <div className="titres">
      <h1> {LivreTitre}</h1>
      <SearchBar placeholder="rechercher l'article..." />
      <div className="container">
        {titre.map((item, index) => {
          return (
            <div className="card" key={index}>
              <Link style={{ textDecoration: 'none' }} to={{
                pathname: `/livres/${LivreTitre.split(" ").join("_")}/${item.title.split(" ").join("_")}`,
                state: {
                  Titre_id: item.id,
                  Livre_id: Livre_id,
                  titre: item.title
                }
              }}>
                <div className="box">
                  <div className="content">
                    <h2> {index < 10 ? '0' + index : index} </h2>

                    <h3>{item.title}</h3>

                    <p>{item.content}</p>
                  </div>
                </div>
              </Link>
            </div>
          )
        }
        )}
      </div>
    </div>
  )
}

export default Titres
