
import Navbar from './components/Navbar/Navbar'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import Home from './components/pages/Home';
import SignUp from './components/pages/SignUp';
import Articles from './components/pages/Articles';
import Article from './components/pages/Article';
import Livres from './components/pages/Livres';
import Docs from './components/pages/Docs';
import Titres from './components/pages/Titres';
import About from './components/pages/About';
// import Footer from './components/Footer/Footer';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/livres' exact component={Livres} />
          <Route path='/livres/:id' exact component={Titres} />
          <Route path='/livres/:id/:articles' exact component={Articles} />
          <Route path='/livres/:id/:articles/:article' component={Article} />
          <Route path='/about' exact component={About} />
          <Route path='/docs' exact component={Docs} />
          <Route path='/sign-up' exact component={SignUp} />
          <Route path='*' exact={true} component={Home} />
        </Switch>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;