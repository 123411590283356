import React from 'react'
import '../../App.scss'
import { Button } from '../Button/Button'
import './HeroSection.scss'
import SearchBar from '../SearchBar/SearchBar'
function HeroSection() {
    return (
        <div className="hero-container" style={{ 
            backgroundImage: `url("/images/mali1.jpg")`,
            backgroundPosition: `fixed` 
          }}>
            <h1>Code de la Famille</h1>
            <p>les fondements de notre sociéte !!</p>
            <SearchBar placeholder="recherche..."/>
            <div className="hero-btns">
                <Button
                    className="btn"
                    Link_i="/livres"
                    buttonStyle='btn--outline'
                    button_size='btn--large'>
                    LES LIVRES
                </Button>
                <Button
                    className="btn"
                    buttonStyle='btn--primary'
                    Link_i="/livres/all/articles"
                    button_size='btn--large'>
                    TOUS LES ARTICLES <i className="far fa-play-circle" />
                </Button>
            </div>
        </div>
    )
}

export default HeroSection
