import React, { useEffect, useRef, useState } from 'react'; //useEffect
import './Articles.scss';
import { Button } from '../Button/Button';
import { Link } from 'react-router-dom';
import firebase from '../utils/firebaseConfig';
import _ from 'underscore';

export default function Article(props) {
  let render = [];
  const [RendValue, setRendValue] = useState([])
  const [article, setArticle] = useState([]);
  const [LivreTitre, setLivreTitre] = useState("");
  const [TitreTitle, setTitreTitle] = useState("");
  const [Livre_id, setLivre_id] = useState("");
  const [Titre_id, setTitre_id] = useState("");
  const [Article_id, setArticle_id] = useState("");

  const ArticlesDB = firebase.database().ref("Articles");
  const TitresDB = firebase.database().ref("Titres");
  const LivresDB = firebase.database().ref("Livres");
  const is_numeric = (str) => {
    return /^\d+$/.test(str);
  };
  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const myPreviousState = usePrevious(article);
  useEffect(() => {
    setLivreTitre(props.match.params.id.split("_").join(" "));
    setTitreTitle(props.match.params.articles.split("_").join(" "));
    setArticle_id(props.match.params.article)
    LivresDB.on('value', (livre_snapshot) => {
      let previousLivre = livre_snapshot.val();
      for (let id in previousLivre) {
        if (previousLivre[id].title === LivreTitre) {
          setLivre_id(id);
        }
      }
    });
    TitresDB.on('value', (livre_snapshot) => {
      let previousLivre = livre_snapshot.val();
      for (let id in previousLivre) {
        if (previousLivre[id].title === TitreTitle) {
          setTitre_id(id);
        }
      }
    });

    ArticlesDB.on('value', (article_snapshot) => {
      let previousArticle = article_snapshot.val();
      let list = [];
      for (let id in previousArticle) {
        if (previousArticle[id].livre_id === Livre_id) {
          if (previousArticle[id].titre_id === Titre_id) {
            list.push({ id, ...previousArticle[id] });
          }
        }
      }
      if (!_.isEqual(myPreviousState, list)) {
        setArticle(list);
      }
      // console.log(article)
    });
    window.scrollTo(0, 0);// eslint-disable-next-line
    render = article.filter(item => item.id === Article_id);
    setRendValue(render[0])
  }, [ArticlesDB, TitresDB, LivresDB, Livre_id, Titre_id, LivreTitre, TitreTitle, myPreviousState, props, setRendValue])

  const navigation = (sens) => {
    let idx = article.indexOf(article.filter(item => item.id === Article_id)[0]);
    let id_temp = article.filter(item => item.id === Article_id)[0] ? article.filter(item => item.id === Article_id)[0].id : "";
    // console.log("ancien :", id_temp)
    // console.log(article[idx+1] ? article[idx+1].id : "")
    render = article.filter(item => item.id === Article_id);

    if (sens === "suiv") {
      return article[idx + 1] ? article[idx + 1].id : id_temp
    }
    else if (sens === "prev") {
      return article[idx - 1] ? article[idx - 1].id : id_temp
    }
  } 
  // eslint-disable-next-line
  const filterContent = (content) => { //  
    // console.log(content)
        return content ? content.replaceAll(":", ": \n\t").replaceAll(";", "; \n\t") : "";
        // return content
  }

  document.title = `Code de la Famille -  ${!is_numeric(TitreTitle) && TitreTitle !== "articles" ? TitreTitle : LivreTitre !== "all" ? LivreTitre : "Tout les Articles"} - Article ${RendValue ? RendValue.Number : ""}`;
  return (
    <div className="article">
      <div className="container">
        <div className="card" >
          <h1> Article N°{RendValue ? RendValue.Number : ""}</h1>
          <Link to={`/livres/${LivreTitre.split(" ").join("_")}/${TitreTitle.trim().split(" ").join("_")}`}>
              <h3>{!is_numeric(TitreTitle) && TitreTitle !== "articles" ? TitreTitle : LivreTitre !== "all" ? LivreTitre : ""}</h3>
          </Link>

          <div className="content">
            {RendValue ? filterContent(RendValue.Content) : ""}
          </div>
          <div className="btns">
            <Link to={`/livres/${LivreTitre.split(" ").join("_")}/${TitreTitle.trim().split(" ").join("_")}/${navigation("prev")}`}>
              <button className='btn--outline'>
                &laquo; précédent
              </button>
            </Link>
            <Button
              buttonStyle='btn--outline'
              key_id={render.id}
              Link_i={{
                pathname: '/livres/all/articles'
              }}>
              Tous les articles
            </Button>
            <Link to={`/livres/${LivreTitre.split(" ").join("_")}/${TitreTitle.split(" ").join("_")}/${navigation("suiv")}`}>
              <button className='btn--outline'>
                suivant &raquo;
              </button>
            </Link>
          </div>
        </div>
      </div>

    </div>
  )
}
