import React from 'react';
import '../../App.scss';
import '../HeroSection/HeroSection.scss';
export default function Docs() {
  // const height = (window.screen.height - 182)+ "px";
  // const width = (window.screen.width- 15)+ "px";
  // console.log(width);

  document.title = `Code de la Famille - Docs`;
  return (
    <div className="Docs">
      <embed className="cofama"
        src="./files/cofama.pdf"
        type="application/pdf"
        frameBorder="0"
        // scrolling="auto"
        height="auto"
        width="80vw"
      ></embed>
      {/* <div style={{width: '100%', height: '600px'}} ></div> */}
    </div>
  )
}

