import React from 'react'
import '../../App.scss';
import Cards from '../Cards/Cards';
import HeroSection from '../HeroSection/HeroSection'

function Home() {

    document.title = `Code de la Famille`;
    return (
        <>
            <HeroSection />
            <Cards />
        </>
    )
}
export default Home;