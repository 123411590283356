import React, { useEffect, useState, useRef } from 'react';
import '../../App.scss';
import firebase from '../utils/firebaseConfig';
import './Livres.scss';
import SearchBar from '../SearchBar/SearchBar'
import { Link } from 'react-router-dom';
import _ from 'underscore';

function Livres() {
  const [livre, setLivre] = useState([]);
  const LivresDB = firebase.database().ref("Livres");

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const myPreviousState = usePrevious(livre);
  useEffect(() => {
    document.title = "Code de la Famille - Livres";
    let list = [];
    LivresDB.on('value', (livre_snapshot) => {
      let previousLivre = livre_snapshot.val();
      for (let id in previousLivre) {
        list.push({ id, ...previousLivre[id] });
      }
      if (!_.isEqual(myPreviousState, list)) {
        setLivre(list);
      }
    });

  }, [LivresDB, myPreviousState, setLivre])


  return (
    <div className="livres">
      <h1> Livres</h1>
      <SearchBar placeholder="rechercher l'article..." title="" />
      <div className="container">
        {livre.map((item, index) => {
          return (
            <div className="card" key={index}>
              <Link style={{ textDecoration: 'none' }} to={{
                pathname: `/livres/${item.title.split(" ").join("_")}`,//.title.split(" ").join("")
                state: {
                  id: item.id,
                  titre: item.title
                }
              }}>
                <div className="box">
                  <div className="content">
                    <h2>0{index}</h2>
                    <h3>{item.title}</h3>

                    <p>{item.content}</p>
                  </div>
                </div>
              </Link>
            </div>
          )
        }
        )}
      </div>
    </div>
  )
}

export default Livres;
