import React, { useEffect, useRef, useState } from 'react';
import './Articles.scss';
import { Link } from 'react-router-dom';
import firebase from '../utils/firebaseConfig';
import _ from 'underscore';

export default function Articles(props) {
  const [filter, setFilter] = useState('')
  const [article, setArticle] = useState([])
  const [LivreTitre, setLivreTitre] = useState("");
  const [TitreTitle, setTitreTitle] = useState("");
  const [Livre_id, setLivre_id] = useState("");
  const [Titre_id, setTitre_id] = useState("");
  const [Titre, setTitre] = useState([]);
  const [Livre, setLivre] = useState([]);

  const ArticlesDB = firebase.database().ref("Articles");
  const TitresDB = firebase.database().ref("Titres");
  const LivresDB = firebase.database().ref("Livres");

  const is_numeric = (str) => {
    return /^\d+$/.test(str);
  }
  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const myPreviousState = usePrevious(article);
  const myPreviousState2 = usePrevious(Livre);
  const myPreviousState3 = usePrevious(Titre);
  useEffect(() => {
    // console.log(props);
    setLivreTitre(props.match.params.id.split("_").join(" "));
    setTitreTitle(props.match.params.articles.split("_").join(" "));
    LivresDB.on('value', (livre_snapshot) => {
      let list = [];
      let previousLivre = livre_snapshot.val();
      for (let id in previousLivre) {
        list.push({ id, ...previousLivre[id] });
        if (previousLivre[id].title === LivreTitre) {
          setLivre_id(id);
        }
      }
      if (!_.isEqual(myPreviousState2, list)) {
        setLivre(list);
      }
    });
    TitresDB.on('value', (livre_snapshot) => {
      let list = [];
      let previousTitre = livre_snapshot.val();
      for (let id in previousTitre) {
        list.push({ id, ...previousTitre[id] });
        if (previousTitre[id].title === TitreTitle) {
          setTitre_id(id);
        }
      }
      if (!_.isEqual(myPreviousState3, list)) {
        setTitre(list);
      }
    });
    ArticlesDB.on('value', (article_snapshot) => {//Mg1PdWXEfL7MhAXiW8h   Mg1QMmBUC5ukHPr56Cl
      let previousArticle = article_snapshot.val();
      let list = [];
      let all = [];
      for (let id in previousArticle) {
        if (previousArticle[id].livre_id === Livre_id) {
          if (previousArticle[id].titre_id === Titre_id) {
            list.push({ id, ...previousArticle[id] });
            
          }
        }
        else if (LivreTitre === "all") {
          all.push({ id, ...previousArticle[id] });

        }
      }
      if (!_.isEqual(myPreviousState, list)) {
        if (LivreTitre !== "all") {
          setArticle(list);
        }
      }
      else if (!_.isEqual(myPreviousState, all)) {
        if (LivreTitre === "all") {
          setArticle(all);
        }
      }
    })
  }, [ArticlesDB, LivresDB, TitresDB, Livre_id, Titre_id, LivreTitre, TitreTitle, myPreviousState, myPreviousState2, myPreviousState3, props])

  const searchText = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
  }
  const DataSearch = article.filter((value) => {
    return Object.keys(value).some(key => value[key].toString().toLowerCase().includes(filter.toString().toLowerCase()))
  })
  const findLivre = (id) => {
    // console.log(Livre.filter(item => item.id === id)[0])
    return Livre.filter(item => item.id === id)[0] ? Livre.filter(item => item.id === id)[0].title.split(" ").join("_") : "0"
  }
  const findTitre = (id) => {
    // console.log(Titre.filter(item => item.id === id)[0] ? Titre.filter(item => item.id === id)[0].title : "rien")
    return Titre.filter(item => item.id === id)[0] ? Titre.filter(item => item.id === id)[0].title.split(" ").join("_") : "0"
  }

  document.title = `Code de la Famille - ${!is_numeric(TitreTitle) && TitreTitle !== "articles" ? TitreTitle : LivreTitre !== "all" ? LivreTitre : "Tout les Articles"}`;
  return (
    <div className="articles">
      <h1> {!is_numeric(TitreTitle) && TitreTitle !== "articles" ? TitreTitle : LivreTitre !== "all" ? LivreTitre : "Tout les Articles"} </h1>
      <div className="search">
        <input className="search-input"  placeholder="Rechercher ..." type="text" value={filter} onChange={searchText.bind(this)} autoFocus="true"/>
      </div>
      <div className="container">
        {DataSearch.map((item, index) => {
          // findTivre(item.titre_id)
          return (
            <div className="card" key={index} >
              <Link style={{ textDecoration: 'none' }} to={{
                pathname: `/livres/${findLivre(item.livre_id)}/${findTitre(item.titre_id)}/${item.id}`,
                state: {
                  Id: item.id,
                  Number: item.Number,
                  Titre: item.TitreArticle,
                  Content: item.Content,
                  source: article,
                }
              }}>
                <div className="box">
                  <div className="content">
                    <h2> {item.Number < 10 ? '0' + item.Number : item.Number} </h2>
                    <h3>  {item.TitreArticle}   </h3>

                  </div>
                </div>
              </Link>
            </div>
          )
        }
        )}
      </div>
    </div>
  )
}

